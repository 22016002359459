.request-page__main-content {
	padding-top: 21rem;
	overflow: hidden;

	@media @max-tablet-screen-size {
		padding: 16rem 0 0;
	}

	@media @max-mobile-screen-size {
		padding: 10rem 0 0;
	}
}

.request-form__title {
	margin-bottom: 0.5rem;
	font-size: 3.5rem;
	line-height: 38px;
	font-weight: 500;
	color: @brand-primary-color;
}

.request-form__subtitle {
	font-size: 1.6rem;
	line-height: 20px;
	font-weight: 300;
	color: @brand-secondary-color;
}

.request-form__content {
	font-size: 1.3rem;
	line-height: 1.5;
	font-weight: @font-weight-light;
	margin-bottom: 50px;
}

.request-form__input {
	width: 100%;
	padding: 10px 0;
	border: none;
	border-bottom: 1px solid @brand-primary-color;
	outline: none;
	color: @brand-secondary-color;
	font-size: 1.2rem;
	.border-radius(0);
}

.request-form__group {
	display: flex;
	margin-bottom: 35px;

	@media @max-tablet-screen-size {
		display: block;
	}
}

.request-form__group--full {
	display: block;
}

.request-form__item--half {
	flex: 1;
	margin-right: 2rem;

	@media @max-tablet-screen-size {
		margin-right: 0;
		margin-top: 35px;
	}

	&:last-child {
		margin-right: 0;
	}
}

.request-form__submit {
	padding: 0 5rem;
}

.request-form__image-wrapper {
	text-align: right;

	@media @max-tablet-screen-size {
		margin-top: 5rem;
	}
}

.request-form__image {
	width: 100%;
	margin-right: -5rem;

	@media @max-tablet-screen-size {
		margin-right: 0;
	}
}

.request-form-bottom {
	margin-top: 6rem;
	overflow: hidden;

	.container {
		padding: 3.5rem 0;

		@media @max-desktop-screen-size {
			padding-bottom: 3.5rem;
		}

		@media @max-tablet-screen-size {
			padding-bottom: 4rem;
		}
	}
}

.request-form-bottom__image {
	margin-bottom: -6.5rem;
	background: url('/assets/img/layout/request-form-mobile.png') no-repeat center top;
  background-size: 435px auto;
	height: 330px;

	@media @highdensity {
		background-image: url('/assets/img/layout/request-form-mobile@2x.png');
	}

	@media @max-tablet-screen-size {
		display: none;
	}
}

.request-form-bottom__title {
	padding-top: 1rem;
	font-size: 3rem !important;
	line-height: 1.2 !important;
}
