.how-does-it-work-section {
  padding: 5rem 0;

  @media @max-mobile-screen-size {
    padding: 4rem 0 0;
  }

  .how-does-it-work-title {
    text-align: center;
    color: @brand-primary-color;
    margin-bottom: 5rem;
  }

  .how-does-it-work-steps-holder {
    padding-top: 6rem;
    .clearfix();

    .how-does-it-work-step {
      width: 11.1%;
      float: left;
      font-size: 1.8rem;
      line-height: 1.2;
      text-align: center;
      .box-sizing();

      @media @max-tablet-screen-size {
        font-size: 1.6rem;
      }

      @media @max-mobile-screen-size {
        width: 100%;
        float: none;
        margin: 0 0 4rem 0;
      }

      &:first-child {
        padding-right: 4rem;

        @media @max-tablet-screen-size {
          padding: 0;
        }
      }

      &:last-child {
        padding-left: 6rem;

        @media @max-tablet-screen-size {
          padding: 0;
        }
      }

      .step-icon {
        display: block;
        font-size: 6rem;
        line-height: 1;
        margin-bottom: 1.8rem;

        @media @max-mobile-screen-size {
          display: inline-block;
          margin: 0;
        }

        &.icon-schedules {
          font-size: 5rem;
          margin-bottom: 2.8rem;

          @media @max-mobile-screen-size {
            margin: 0;
          }
        }

        &.icon-phone {
          font-size: 5.6rem;
          margin-bottom: 2.2rem;

          @media @max-mobile-screen-size {
            margin: 0;
          }
        }
      }

      .step-description {
        @media @max-mobile-screen-size {
          display: inline-block;
          text-align: left;
          position: relative;
          top: -.6rem;
          padding-left: 1rem;
        }
      }
    }

    .how-does-it-work-step-divider {
      width: 11.1%;
      float: left;
      text-align: center;
      font-size: 3.8rem;
      color: @brand-primary-color;
      padding-top: 1rem;

      @media @max-mobile-screen-size {
        width: 100%;
        float: none;
        margin: 0 0 4rem 0;
        padding: 0;
        transform: rotate(90deg);
      }
    }
  }

  .our-scheme-holder {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 7rem;
    text-align: center;

    @media @max-mobile-screen-size {
      flex-direction: column;
    }

    .our-scheme-patient,
    .our-scheme-front-desk,
    .our-scheme-patientpal {
      display: block;
      float: left;

      @media @max-mobile-screen-size {
      //   float: none;
        width: 100%;
        margin: 0 0 2rem;
      }
    }

    .our-scheme-patient,
    .our-scheme-front-desk {
      padding-top: 2.6rem;

      // @media @max-mobile-screen-size {
      //   padding: 0;
      // }
    }

    .our-scheme-patient {
      .our-scheme-inner-content {
        border-right: 0;
        padding: 0;

        @media @min-tablet-screen-size {
          .border-radiuses(0, 0, 10px, 10px);
        }

        // @media @max-tablet-screen-size {
        //   padding: 3rem 2rem;
        // }
      }
    }

    .our-scheme-front-desk {
      .our-scheme-inner-content {
        border-left: 0;
        // padding: 4.8rem 2.2rem 4.8rem 1.2rem;

        @media @min-tablet-screen-size {
          .border-radiuses(10px, 10px, 0, 0);
        }

        // @media @max-tablet-screen-size {
        //   padding: 3rem 2rem;
        // }
      }
    }

    .our-scheme-patientpal {
      margin: 0;
      border: 2px solid @brand-primary-color;
      .border-radius(6px);
      width: 28rem;
      background-color: #F8F9FC;
      // color: white;

      @media @max-mobile-screen-size {
        // margin: 0 0 2rem;
        width: 100%;
        margin-top: 4rem;
      }

      .our-scheme-inner-content {
        width: 100%;
        border: none;

        .our-scheme-name {
          margin-top: -4rem;
        }
      }

      .our-scheme-list {
        margin: 0;
        padding-top: 0;

        @media @max-mobile-screen-size {
          margin-bottom: 3rem;
        }
      }

      .our-scheme-list-item {
        // color: white;
        // border-color: fade(white, 30%) !important;
      }
    }

    .our-scheme-inner-content {
      width: 24.5rem;
      padding: 0; // 4.8rem 2rem;
      // border: 1px solid @brand-primary-color;
      .box-sizing();

      // @media @max-tablet-screen-size {
      //   width: 22.2rem;
      //   padding: 3rem 2rem;
      // }

      @media @max-mobile-screen-size {
        width: 100%;
      }
    }

    .our-scheme-name {
      display: inline-block;
      font-size: 2.2rem;
      line-height: 1.3;
      letter-spacing: -.8px;
      text-transform: uppercase;
      padding: 0;
      // border-top: 2px solid @brand-primary-color;
      // border-bottom: 2px solid @brand-primary-color;
      font-weight: @font-weight-bold;
      margin-bottom: 1.6rem;

      img {
        width: 50px !important;
      }

      @media @max-tablet-screen-size {
        font-size: 2.8rem;
      }
    }

    .our-scheme-list {
      display: block;
      margin-top: 1.5rem;
      font-size: 1.5rem;
      line-height: 1.4;
      font-weight: @font-weight-light;
      background-color: #F8F9FC;
      color: #7a7a7a;
      .border-radius(6px);

      @media @max-tablet-screen-size {
        font-size: 1.3rem;
      }

      .our-scheme-list-item {
        padding: 1.2rem 2.4rem;
        border-bottom: 1px solid #ececed;
        letter-spacing: .6px;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}