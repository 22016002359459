.integration-section {
  @integration-padding: 11rem;
  background-color: rgba(237, 239, 242, 0.4);
  border-top: 1px solid white;

  @media @desktop-screen-size {
    overflow: hidden;
  }

  br {
    @media @max-mobile-screen-size {
      display: none;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .integration-title {
    color: @ppal-blue-color;
    font-size: 3.5rem;
    line-height: 3.7rem;
    letter-spacing: 1.7px;
    margin-top: @integration-padding;
    margin-bottom: 2rem;

    @media @max-mobile-screen-size {
      font-size: 2.8rem;
    }
  }

  .integration-text {
    max-width: 47rem;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: .7px;
    margin-bottom: @integration-padding;

    strong {
      color: @brand-primary-color;
    }
  }

  .integration-image {
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // right: 5%;

    // @media @max-tablet-screen-size {
    //   position: relative;
    //   bottom: -@integration-padding;
    //   right: auto;
    //   width: 100%;
    // }

    // @media @max-mobile-screen-size {
    //   position: relative;
    //   bottom: auto;
    //   width: 100%;
    //   height: auto;
    //   margin-top: 4rem;
    // }

    img {
      max-width: 100%;
      max-height: 100%;
      // float: right;
    }
  }
}