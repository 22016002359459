//Grid setup
@main-padding: 1.5rem;
@half-padding: @main-padding/2;
@double-padding: @main-padding*2;

// Columns ang Guetters
@grid-columns: 12;
@grid-gutter-width: @double-padding;
//Half on heach side = @main-padding

// Screen sizes
@screen-xs: 480px;
@screen-sm: 768px;
@screen-md: 992px;
@screen-lg: 1300px;

// Fixed container widths
@container-sm: (90%);
@container-md: (920px + @grid-gutter-width);
@container-lg: (1110px + @grid-gutter-width);

//  Basic colors
@black: #000;
@white: #fff;

@gray: #333;
@gray-dark: darken(@gray, 50%);
@gray-darker: darken(@gray, 70%);
@gray-light: lighten(@gray, 50%);
@gray-lighter: lighten(@gray, 70%);

//  Brand colors
@brand-primary-color: #3949ab;
@brand-secondary-color: #58595b;
@ppal-blue-color: #3F51B5;

@brand-main-color: @brand-secondary-color;
@brand-main-color-hover: @brand-primary-color;

//  Brand main colors modifications
@brand-primary-color-darker: darken(@brand-primary-color, 10%);
@brand-secondary-color-darker: darken(@brand-secondary-color, 10%);

//  Success and Error colors
@brand-success-color: #555555;
@brand-success-color-darker: darken(@brand-success-color, 10%);

@brand-error-color: #ed512a;
@brand-error-color-darker : darken(@brand-error-color, 10%);

//  Borders
@main-border-color: @brand-main-color;
@main-border-radius: 4px;

//  Typography
@font-primary: 'Ubuntu', sans-serif;
// @font-secondary: 'proxima-nova', Helvetica, Arial, sans-serif;
@font-titles: @font-primary;
@font-weight-light: 300;
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semi-bold: 600;
@font-weight-bold: 700;
@font-weight-black: 800;

//  Typo sizes
@font-size-tiny: .70rem;
@font-size-small: .80rem;
@font-size-basic: 1rem;
@font-size-medium: 1.6rem;
@font-size-large: 2.8rem;
@font-size-huge: 3.54rem;

// Text selection colors
@text-selection-color: @brand-primary-color;
@text-selection-background: @white;

//  Forms
@inputs_margin_bottom: 1.5rem;