.login__main-content {
    padding-top: 21rem;
    overflow: hidden;

    .login-form__group {
        display: flex;
        margin-bottom: 35px;
    }

    .login__item {
        flex: 1;

        .login__domain {
            margin-left: 5px;
            font-size: 1.2rem;
            line-height: 37px
        }
    }

    .login__image-wrapper {
        @media @max-tablet-screen-size {
            display: none;
        }
    }
}
