.homepage-hero-section {
  position: relative;
  background: url("/assets/img/layout/homepage-hero-background.png") no-repeat center top;
  background-size: cover;
  background-position: bottom;
  padding: 15rem 0 11.5rem;
  overflow: hidden;

  .homepage-hero-mockup {
    height: 530px;
    background: url("/assets/img/layout/homepage-hero-mockup.png") no-repeat center center;
    background-size: 100% auto;

    @media @highdensity {
      background-image: url("/assets/img/layout/homepage-hero-mockup@2x.png");
    }

    @media @max-tablet-screen-size {
      // position: relative;
      // right: auto;
      // bottom: auto;
      // left: auto;
      // margin: 0 -2rem 0 0;
      // padding: 0 2rem 0 0;
      // width: 100%;
      // height: 40rem;
      // background-size: contain;
      max-width: 820px; // this is to ensure the image doesn't start to clip
    }
  }

  .homepage-hero-content-holder {
    padding-top: 7.7rem;

    @media @max-tablet-screen-size {
      padding-top: 0;
      margin: 0 auto;
      max-width: 34rem;
    }
  }

  .homepage-hero-title {
    color: @brand-primary-color;
    font-size: 2.7rem;

    @media @max-tablet-screen-size {
      font-size: 3rem;
      line-height: 1;
      letter-spacing: 0;
      text-align: center;
    }
  }

  .homepage-hero-subtitle {
    @media @max-tablet-screen-size {
      text-align: center;
    }
  }

  .homepage-hero-form {
    margin-top: 5.2rem;
    max-width: 33.7rem;
  }

  .homepage-hero-form-note {
    display: block;
    margin-top: 3.8rem;
    font-size: 1.5rem;
    font-weight: @font-weight-medium;
    letter-spacing: .6px;
    opacity: .5;
  }
}