.testimonials-section {
  padding: 8.4rem 0 13.4rem;

  @media @max-mobile-screen-size {
    padding: 4rem 0;
  }

  .testimonials-holder {
    margin-top: 5.3rem;

    @media @max-mobile-screen-size {
      margin-top: 2rem;
    }

    .center-align {
      text-align: center;
    }

    .right-align {
      text-align: right;

      @media @max-tablet-screen-size {
        text-align: center;
        margin-top: 8rem;
      }

      @media @max-mobile-screen-size {
        margin-top: 0;
      }
    }

    .center-sm {
      @media @tablet-screen-size {
        margin-left: 25%;
      }

      @media @max-mobile-screen-size {
        margin-left: 0;
      }
    }
  }

  .testimonial {
    display: inline-block;
    max-width: 27.6rem;
    text-align: center;
    font-weight: @font-weight-medium;

    @media @max-mobile-screen-size {
      max-width: 100%;
      margin-top: 6rem;
      padding: 0 2rem;
    }
  }

  .testimonial-image {
    width: 14rem;
    height: 14rem;
    overflow: hidden;
    .border-radius(50%);
    margin: 0 auto 2.4rem;
  }

  .testimonial-name {
    font-size: 2rem;
    line-height: 1;
    letter-spacing: .8px;
    color: @brand-primary-color;
    margin-bottom: .4rem;
  }

  .testimonial-location {
    font-size: 1.2rem;
    letter-spacing: .8px;
    text-transform: uppercase;
    margin-bottom: 1.8rem;
  }

  .testimonial-text {
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-weight: @font-weight-light;
    font-style: italic;
  }
}