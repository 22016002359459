.app-footer {
	padding: 5.5rem 0 1.6rem;

	@media @max-mobile-screen-size {
		padding: 3rem 0 2rem;
	}

	.app-footer-logo {
		display: block;
		width: 17.1rem;
		height: 5.5rem;
		background: url("/assets/img/svg/logo.svg") no-repeat left top;
		background-size: 100% auto;
	}

	.app-footer-navs-holder {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: stretch;

		@media @tablet-screen-size {
			// padding-left: 4.6rem;
		}

		// @media @max-mobile-screen-size {
		// 	display: block;
		// 	padding: 0;
		// }
	}

	.app-footer-nav {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		// @media @tablet-screen-size {
		// 	flex-basis: auto;
		// 	padding-right: 8rem;
		// }

		@media @max-mobile-screen-size {
			width: 100%;
			margin-top: 3rem;
			padding-right: 0;
		}

		&:last-of-type {
			flex-grow: 0;
		}

		&.app-footer-logo-holder {
			flex-grow: 0;
			padding-right: 7rem;
			text-align: left;

			// @media @tablet-screen-size {
			// 	width: 100%;
			// 	padding: 0;
			// 	margin: 0 0 3rem -4.6rem;
			// }

			@media @max-mobile-screen-size {
				padding: 0;
				margin: 0;
			}
		}

		&:last-child {
			padding-right: 0;
		}
	}

	.app-footer-nav-title {
		font-size: 1.3rem;
		line-height: 1;
		letter-spacing: .6px;
		text-transform: uppercase;
		font-weight: @font-weight-bold;
		margin-bottom: 1.8rem;

		@media @max-mobile-screen-size {
			width: 100%;
			margin-bottom: 1rem;
		}
	}

	.app-footer-nav-item {
		display: block;
		font-size: 1.4rem;
		line-height: 2rem;
		letter-spacing: .6px;
		font-weight: @font-weight-light;
		text-decoration: none;
		margin-bottom: .1rem;

		&:hover,
		&:active {
			color: @brand-primary-color;
		}

		&.disabled {
			cursor: default;

			&:hover {
				color: @brand-secondary-color;
			}
		}
	}

	.app-footer-nav-item-social {
		display: inline-block;
		color: @brand-primary-color;
		font-size: 2rem;
		margin-right: 1rem;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}

	.app-footer-bottom {
		padding: 1.7rem 0 0;
		margin: 4.7rem 0 0;
		border-top: 1px solid #e6e6e6;

		@media @max-mobile-screen-size {
			margin: 2rem 0 0;
		}

		.container {
			display: flex;
			justify-content: space-between;

			@media @max-mobile-screen-size {
				flex-direction: column-reverse;
			}
		}

		.app-footer-bottom-links {
			flex-grow: 1;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@media @max-mobile-screen-size {
				flex-direction: column;
				align-items: flex-start;
				margin-bottom: 2rem;
			}

			.app-footer-nav-item {
				margin-left: 3rem;

				@media @max-mobile-screen-size {
					margin-left: 0;
				}
			}
		}

		.app-footer-copyright {
			font-size: 1.3rem;
			letter-spacing: .3px;
			font-weight: @font-weight-medium;
			color: @brand-primary-color;

			@media @max-mobile-screen-size {
				line-height: 1.2;
			}
		}
	}
}
