.section-title-holder {
  text-align: center;

  .section-title {
    position: relative;
    color: @brand-primary-color;
    padding-bottom: 1.7rem;
    margin: 0 0 .6rem;

    &:after {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -3.4rem;
      content: '';
      display: block;
      width: 6.8rem;
      height: 1px;
      background: @brand-secondary-color;
    }
  }

  .section-subtitle {
    color: @brand-secondary-color;
    font-size: 1.5rem;
    line-height: 1;
    margin: 1.5rem auto 0 auto;
    letter-spacing: .6px;
  }

  &.mod-white {
    .section-title {
      color: white;

      &:after {
        background: white;
      }
    }

    .section-subtitle {
      color: white;
    }
  }
}