.btn {
  display: inline-block;
  height: 4.1rem;
  padding: 0 2.3rem;
  // font-family: @font-secondary;
  font-size: 1.3rem;
  line-height: 4.1rem;
  letter-spacing: .5px;
  color: @brand-primary-color;
  font-weight: @font-weight-black;
  border: 1px solid transparent;
  box-shadow: none;
  background: transparent;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  .border-radius(3rem);
  .box-sizing(border-box);
  .transition(.1s ease);

  &:hover {
    text-decoration: none;
  }

  // Sizing
  &.btn-sm {
    font-size: 1.1rem;
    height: 3.4rem;
    line-height: 3.4rem;
    .border-radius(2rem);
  }

  &.btn-block {
    width: 100%;
  }

  // Colors
  &.btn-green-outline {
    border-color: @brand-primary-color;
    color: @brand-primary-color;

    &:hover,
    &:active {
      background-color: @brand-primary-color;
      color: white;
    }
  }

  &.btn-green-fill {
    border-color: @brand-primary-color;
    background-color: @brand-primary-color;
    color: white;

    &:hover,
    &:active {
      border-color: @brand-primary-color;
      background-color: fade(@brand-primary-color, 80%);
    }
  }

  &.btn-white-outline {
    border-color: white;
    color: white;

    &:hover,
    &:active {
      background-color: white;
      color: @brand-primary-color;
    }
  }
}

// Inputs
.form-control {
  display: block;
  width: 100%;
  height: 3.4rem;
  font-size: 1.2rem;
  line-height: 1.4;
  padding: 0 1.8rem;
  margin: 0;
  background: white;
  box-shadow: none;
  border: 1px solid @brand-primary-color;
  .border-radius(1.6rem);
  .box-sizing(border-box);
  outline: none;
}

.form-group {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  margin-bottom: 1.7rem;

  > .form-group-inner {
    display: block;
    flex-grow: 1;

    .form-control {
      .border-radius(0);
    }

    &:first-child {
      .form-control {
        .border-radiuses(0, 0, 1.6rem, 1.6rem);
        border-right: none;
      }
    }

    &:last-child {
      .form-control {
        .border-radiuses(1.6rem, 1.6rem, 0, 0);
      }
    }
  }
}
