.why-choose-us-section {
	// padding: 10rem 0;
	background: white;
	padding: 6rem 0;
	// background-size: 102rem auto;

	@media @max-mobile-screen-size {
		padding: 6rem 0 2rem;
	}

	.row {
		@media @max-mobile-screen-size {
			padding: 0 1.5rem;
		}
		.col-xs-6 {
			@media @max-mobile-screen-size {
				padding: 0;
			}
		}
	}

	.why-choose-us-rows-holder {
		position: relative;

		&:before {
			position: absolute;
			top: 0;
			bottom: 8rem;
			left: 50%;
			display: block;
			content: '';
			width: 1px;
			background: @brand-primary-color;

			@media @max-mobile-screen-size {
				top: -8rem;
			}
		}
	}

	.why-choose-us-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		// align-items: center;
		padding: 2.9rem 0 0;

		@media @max-mobile-screen-size {
			position: relative;
			display: block;
			width: 100%;
			text-align: center;
			z-index: 10;
		}

		&.pt0 {
			padding-top: 0;
		}

		&.header {
			.text {
				padding-bottom: 0;
			}
			p {
				margin: 0;
			}
		}

		.text {
			// width: 26rem;
			width: calc(50% - 35px);
			padding: 2rem 0;

			// @media @max-desktop-screen-size {
			// 	width: 22rem; // 19rem;
			// }

			@media @max-mobile-screen-size {
				display: block;
				width: 100%;
				text-align: center;
				padding: 1rem 0 .2rem;
				margin-bottom: 4rem;
			}

			&.text-right {
				text-align: right;
			}

			&.patient-pal strong {
				color: @brand-primary-color;
			}

			@media @max-mobile-screen-size {
				text-align: center !important;
				background: white;
			}

			strong {
				font-size: 2.0rem;
			}

			p {
				line-height: 1.2;

				@media @max-tablet-screen-size {
					font-size: 1.3rem;
				}

				b {
					line-height: 1.4;
				}
			}
		}

		.icon {
			width: 4rem;
			font-size: 4rem;
			padding: 2rem 1.5rem;
			z-index: 2;
			background: #fff;

			@media @max-tablet-screen-size {
				font-size: 2.8rem;
			}

			@media @max-mobile-screen-size {
				display: block;
				width: 100%;
				text-align: center;
				padding: 1rem 0 0;
				margin-top: 1rem;
				background: white;
			}

			&.left {
				color: @brand-primary-color;
			}
		}
	}
}
