//This is used to show what media query is applied
body.is-showing-responsive-tests {
	&:before {
		position: fixed;
		z-index: 12000;
		bottom: 10px;
		right: 10px;
		width: auto;
		color: white;
		text-align: center;
		padding: 1em;
		opacity: .9;
		font-size: .9em;
		line-height: 1;
		text-transform: uppercase;
		.border-radius(@main-border-radius);

		// Smartphones
		@media @mobile-screen-size {
			background: red;
			content: "Mobile";
		}

		// Tablets
		@media @tablet-screen-size {
			background: blue;
			content: "Tablets";
		}

		// Desktop
		@media @desktop-screen-size {
			background: darkgoldenrod;
			content: "Desktop";
		}

		// Desktop HD
		@media @desktop-hd-screen-size {
			background: pink;
			content: "Desktop HD";
		}
	}
}