.front-desk-section {
  padding: 8.5rem 0 5.6rem;

  @media @max-mobile-screen-size {
    padding: 6rem 0 3rem;
    text-align: center;
  }

  .front-desk-content-holder {
    position: relative;
    margin-top: 2.2rem;
    text-align: center;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // .clearfix();
  }

  .front-desk-image {
    height: 400px;
    text-align: center;

    // @media @desktop-screen-size {
    //   width: 30rem;
    //   margin-left: -15rem;
    //   top: 0;
    // }

    // @media @max-tablet-screen-size {
    //   position: relative;
    //   top: auto;
    //   left: auto;
    //   margin: 2rem 0 0;
    //   width: 100%;
    //   height: auto;
    // }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    @media @max-mobile-screen-size {
      height: 300px;
    }
  }

  .front-desk-description {
    max-width: 275px;
    margin-top: 2rem;

    .front-desk-icon {
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    .front-desk-icon,
    strong {
      color: @brand-primary-color;
    }

    &:after {
      content: "";
      display: block;
      margin: 1rem auto 0 auto;
      background: @brand-primary-color;
      height: 1px;
      width: 80%;
    }
  }
}
