@icomoon-font-family: "patientpal-icon-font";
@icomoon-font-path: "/assets/fonts/icon-font/fonts";

@icon-stethoscope: "\e91c";
@icon-cogs: "\e91d";
@icon-settings: "\e91d";
@icon-gears: "\e91d";
@icon-options: "\e91d";
@icon-generate: "\e91d";
@icon-pig: "\e91e";
@icon-money: "\e91e";
@icon-bank: "\e91e";
@icon-files: "\e91f";
@icon-stack: "\e91f";
@icon-papers: "\e91f";
@icon-tablet: "\e920";
@icon-phone: "\e920";
@icon-phone1: "\e921";
@icon-cellular-phone: "\e921";
@icon-mobile: "\e921";
@icon-clipboard: "\e922";
@icon-list: "\e922";
@icon-board: "\e922";
@icon-profile-clipboard: "\e923";
@icon-profile: "\e924";
@icon-changing-money: "\e91a";
@icon-user: "\e91b";
@icon-handshake: "\e918";
@icon-shuffle: "\e919";
@icon-chat: "\e912";
@icon-patient-visit: "\e913";
@icon-insurance-check: "\e914";
@icon-phone2: "\e915";
@icon-arrow-right: "\e916";
@icon-schedules: "\e917";
@icon-facebook: "\e90e";
@icon-instagram: "\e90f";
@icon-twitter: "\e910";
@icon-youtube: "\e911";
@icon-authorization: "\e900";
@icon-card: "\e901";
@icon-check-in: "\e902";
@icon-claims: "\e903";
@icon-delete-circle: "\e904";
@icon-digital-intake: "\e905";
@icon-eligibility: "\e906";
@icon-follow-up: "\e907";
@icon-image: "\e908";
@icon-insurance: "\e909";
@icon-lock: "\e90a";
@icon-money-increase: "\e90b";
@icon-payment: "\e90c";
@icon-stopwatch: "\e90d";
@icon-integration-instructions: "\e925";
@icon-receipt-long: "\e926";
@icon-verified-user: "\e927";
@icon-chat-bubble: "\e928";
@icon-headset-mic: "\e929";
@icon-smartphone: "\e92a";
@icon-bolt: "\e92b";
@icon-clock: "\e92d";
@icon-access-time: "\e92d";
@icon-qrcode: "\e938";
@icon-credit-card: "\e93f";
@icon-display: "\e956";
@icon-mobile1: "\e958";
@icon-mobile2: "\e959";
@icon-tablet1: "\e95a";
@icon-upload: "\e961";
@icon-bubbles: "\e96c";
@icon-bubbles2: "\e96d";
@icon-bubble2: "\e96e";
@icon-bubbles3: "\e96f";
@icon-cogs1: "\e995";
@icon-power: "\e9b5";
@icon-clipboard1: "\e9b8";
@icon-list-numbered: "\e9b9";
@icon-tree: "\e9bc";
@icon-upload3: "\e9c8";
@icon-happy: "\e9df";
@icon-circle-right: "\ea42";
@icon-circle-left: "\ea44";
@icon-tab: "\ea45";
@icon-make-group: "\ea58";

