.integrations-banner {
  display: flex;
  justify-content: center;
  align-items: center;

  .partners {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 3rem 0;
    overflow: scroll;
    max-width: 90vw;
  }

  img {
    max-width: 100%;
    margin-right: 5rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
