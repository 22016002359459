.pay__main-content {
    padding-top: 21rem;
    overflow: hidden;

    .pay-form__group {
        display: flex;
        margin-bottom: 35px;
    }

    .pay__item {
        flex: 1;
    }

    .pay__image-wrapper {
        @media @max-tablet-screen-size {
            display: none;
        }
    }
}
