.text-page {
	padding: 21rem 0 6rem;

	@media @max-tablet-screen-size {
		padding: 16rem 0 0;
	}

	@media @max-mobile-screen-size {
		padding: 10rem 0 0;
	}

	ol {
		margin: 0;
		padding: 0 0 0 3.4rem;

		li {
			font-size: 2.4rem;
			margin: 4rem 0 2rem;
			padding: 0;
		}
	}

	ul {
		li {
			font-size: 1.5rem;
			font-weight: @font-weight-light;
		}
	}

	h2 {
		margin-top: 2.5rem;
	}

	h3, h4 {
		font-size: 2rem;
		line-height: 3.3rem;
		letter-spacing: 1.1px;
		font-weight: 500;
	}

	h4 {
		font-size: 1.7rem;
		font-weight: normal;
	}
}
