.signed-clinics-section {
  padding: 10.7rem 0 10.2rem;
  border-bottom: 1px solid #e6e6e6;

  @media @max-mobile-screen-size {
    padding: 7rem 0 5rem;
  }

  .signed-clinics-holder {
    display: table;
  }

  .signed-clinics-number {
    display: table-cell;
    vertical-align: top;
    font-size: 12.9rem;
    line-height: 7rem;
    font-weight: @font-weight-light;
    color: @brand-primary-color;

    @media @desktop-screen-size {
      font-size: 10rem;
      line-height: 5rem;
    }
  }

  .signed-clinics {
    display: table-cell;
    vertical-align: top;
    padding-left: 1rem;
  }

  .signed-clinics-title {
    display: inline-block;
    font-size: 4.2rem;
    line-height: 4.5rem;
    letter-spacing: 6px;
    padding-bottom: .3rem;
    border-top: 3px solid @brand-primary-color;
    border-bottom: 3px solid @brand-primary-color;
    text-transform: uppercase;
    font-weight: @font-weight-light;
    margin-bottom: .3rem;

    @media @desktop-screen-size {
      font-size: 3.6rem;
      line-height: 4.2rem;
    }
  }

  .signed-clinics-subtitle {
    font-size: 3.4rem;
    line-height: 1;
    padding-bottom: .4rem;

    @media @desktop-screen-size {
      font-size: 2.8rem;
    }
  }

  .signed-clinics-single-clinic {
    width: 50%;
    float: left;
    margin-top: 4.8rem;
    padding-left: .4rem;
    .box-sizing();

    @media @desktop-screen-size {
      margin-top: 2rem;
    }

    @media @max-mobile-screen-size {
      width: 100%;
      float: none;
      margin-top: 3rem;
    }

    &:nth-child(2n + 0) {
      padding-left: 4.8rem;

      @media @max-mobile-screen-size {
        padding: 0;
      }
    }

    .signed-clinics-clinic-name {
      color: @brand-primary-color;
      font-size: 2rem;
      line-height: 1;
      margin-bottom: .6rem;
      font-weight: @font-weight-medium;
    }

    .signed-clinics-clinic-location {
      color: #848484;
      font-size: 1.1rem;
      line-height: 1.4;
      font-weight: @font-weight-bold;
      text-transform: uppercase;
      letter-spacing: 1.3px;
    }
  }

  .signed-clinics-image-holder {
    text-align: right;

    @media @max-tablet-screen-size {
      padding: 4rem 0 0;
      text-align: center;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}