.green-explain-section {
  background: @brand-primary-color;
  padding: 7rem 0 4.8rem;
  text-align: center;

  @media @max-mobile-screen-size {
    padding: 5rem 0 1rem;
  }

  &.expanded {
    padding: 8.4rem 0 3.7rem;

    @media @max-mobile-screen-size {
      padding: 5rem 0 1rem;
    }

    .green-explain-item-icon {
      font-size: 5rem;
    }
  }

  .container .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media @max-mobile-screen-size {
      flex-direction: column;
    }
  }

  .section-title-holder {
    margin: 0 0 4rem;

    @media @max-mobile-screen-size {
      margin: 0 0 5rem;
    }
  }

  .green-explain-item {
    display: inline-block;
    width: 29.6rem;
    margin: 0 0 3.2rem -1rem;
    padding: 0 4rem;
    box-sizing: border-box;

    @media @tablet-screen-size {
      width: 46%;
      min-height: 16rem;
      float: left;
      margin: 0 0 8rem;
    }

    @media @max-mobile-screen-size {
      width: 100% !important;
      float: none !important;
      margin: 0 0 4rem !important;
    }

    &.pull-left {
      float: left;
      margin: 0;

      @media @tablet-screen-size {
        width: 46%;
        float: left;
        margin: 0 0 8rem;
      }
    }

    &.pull-right {
      float: right;
      width: 28.6rem;
      margin: 0;

      @media @tablet-screen-size {
        width: 46%;
        float: left;
        margin: 0 0 8rem;
      }
    }

    &.center-sm {
      @media @tablet-screen-size {
        width: 46%;
        float: left;
        margin: 0 0 0 27%;
      }
    }

    &:nth-child(2n+0) {
      @media @tablet-screen-size {
        float: right;
      }
    }
  }

  .green-explain-item-icon {
    display: inline-block;
    font-size: 6rem;
    margin-bottom: 2rem;
    background-size: 100% auto;
    color: white;

    @media @max-mobile-screen-size {
      margin-bottom: 1rem;
    }
  }

  .green-explain-item-title {
    display: block;
    font-size: 2rem;
    line-height: 1.2;
    color: white;
    font-weight: @font-weight-bold;
    letter-spacing: .8px;
    margin-bottom: 1.6rem;
  }

  p {
    padding: 0;
    margin: 0;
    font-size: 1.9rem;
    line-height: 1.3;
    font-weight: @font-weight-medium;
    color: white;
    letter-spacing: .6px;
    text-align: center;
    // text-align: justify;
    // text-justify: inter-word;
  }
}