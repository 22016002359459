.partners-section {
  padding: 7.5rem 0 5.9rem;
  border-bottom: 1px solid #e6e6e6;
  display: none; //This is hidden until Adam gives us green light

  @media @max-mobile-screen-size {
    padding: 4rem 0 0;
  }

  .partners-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;

    @media @max-mobile-screen-size {
      display: block;
    }
  }

  .partner {
    display: block;
    flex-grow: 1;
    text-align: center;

    @media @max-mobile-screen-size {
      width: 100%;
      float: left;
      text-align: center !important;
      margin-bottom: 4rem;
    }

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }

    img {
      max-width: 90%;
    }
  }
}