// Grid system
// --------------------------------------------------

// Container widths
.container {
	.container-fixed();

	@media (min-width: @screen-sm-min) {
		width: @container-sm;
	}
	@media (min-width: @screen-md-min) {
		width: @container-md;
	}
	@media (min-width: @screen-lg-min) {
		width: @container-lg;
	}
}

// Fluid container
.container-fluid {
	.container-fixed();
}

// Row
.row {
	.make-row(@double-padding);
}

// Column
.column {
	.box-sizing(border-box);
}

// MAKE GRID
// --------------------------------------------------

// Columns
.make-grid-columns();

// Extra small grid
.make-grid(xs);

// Small grid
@media (min-width: @screen-sm-min) {
	.make-grid(sm);
}

// Medium grid
@media (min-width: @screen-md-min) {
	.make-grid(md);
}

// Large grid
@media (min-width: @screen-lg-min) {
	.make-grid(lg);
}

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `@grid-columns`.

.make-grid-columns() {
	// Common styles for all sizes of grid columns, widths 1-12
	.col(@index) {
		// initial
		@item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
		.col((@index + 1), @item);
	}
	.col(@index, @list) when (@index =< @grid-columns) {
		// general; "=<" isn't a typo
		@item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
		.col((@index + 1), ~"@{list}, @{item}");
	}
	.col(@index, @list) when (@index > @grid-columns) {
		// terminal
		@{list} {
			position: relative;
			// Prevent columns from collapsing when empty
			min-height: 1px;
			// Inner gutter via padding
			padding-left: (@grid-gutter-width / 2);
			padding-right: (@grid-gutter-width / 2);
		}
	}
	.col(1); // kickstart it
}

.float-grid-columns(@class) {
	.col(@index) {
		// initial
		@item: ~".col-@{class}-@{index}";
		.col((@index + 1), @item);
	}
	.col(@index, @list) when (@index =< @grid-columns) {
		// general
		@item: ~".col-@{class}-@{index}";
		.col((@index + 1), ~"@{list}, @{item}");
	}
	.col(@index, @list) when (@index > @grid-columns) {
		// terminal
		@{list} {
			float: left;
		}
	}
	.col(1); // kickstart it
}

.calc-grid-column(@index, @class, @type) when (@type = width) and (@index > 0) {
	.col-@{class}-@{index} {
		width: percentage((@index / @grid-columns));
	}
}

.calc-grid-column(@index, @class, @type) when (@type = push) and (@index > 0) {
	.col-@{class}-push-@{index} {
		left: percentage((@index / @grid-columns));
	}
}

.calc-grid-column(@index, @class, @type) when (@type = push) and (@index = 0) {
	.col-@{class}-push-0 {
		left: auto;
	}
}

.calc-grid-column(@index, @class, @type) when (@type = pull) and (@index > 0) {
	.col-@{class}-pull-@{index} {
		right: percentage((@index / @grid-columns));
	}
}

.calc-grid-column(@index, @class, @type) when (@type = pull) and (@index = 0) {
	.col-@{class}-pull-0 {
		right: auto;
	}
}

.calc-grid-column(@index, @class, @type) when (@type = offset) {
	.col-@{class}-offset-@{index} {
		margin-left: percentage((@index / @grid-columns));
	}
}

// Basic looping in LESS
.loop-grid-columns(@index, @class, @type) when (@index >= 0) {
	.calc-grid-column(@index, @class, @type);
	// next iteration
	.loop-grid-columns((@index - 1), @class, @type);
}

// Create grid for specific class
.make-grid(@class) {
	.float-grid-columns(@class);
	.loop-grid-columns(@grid-columns, @class, width);
	.loop-grid-columns(@grid-columns, @class, pull);
	.loop-grid-columns(@grid-columns, @class, push);
	.loop-grid-columns(@grid-columns, @class, offset);
}

// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
.container-fixed(@gutter: @grid-gutter-width) {
	margin-right: auto;
	margin-left: auto;
	padding-left: (@gutter / 2);
	padding-right: (@gutter / 2);
	&:extend(.clearfix all);

	@media (max-width: @screen-xs-max) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

// Creates a wrapper for a series of columns
.make-row(@gutter: @grid-gutter-width) {
	margin-left: -(@gutter / 2);
	margin-right: -(@gutter / 2);
	&:extend(.clearfix all);
}

// Generate the extra small columns
.make-xs-column(@columns; @gutter: @grid-gutter-width) {
	position: relative;
	float: left;
	width: percentage((@columns / @grid-columns));
	min-height: 1px;
	padding-left: (@gutter / 2);
	padding-right: (@gutter / 2);
}

.make-xs-column-offset(@columns) {
	margin-left: percentage((@columns / @grid-columns));
}

.make-xs-column-push(@columns) {
	left: percentage((@columns / @grid-columns));
}

.make-xs-column-pull(@columns) {
	right: percentage((@columns / @grid-columns));
}

// Generate the small columns
.make-sm-column(@columns; @gutter: @grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left: (@gutter / 2);
	padding-right: (@gutter / 2);

	@media (min-width: @screen-sm-min) {
		float: left;
		width: percentage((@columns / @grid-columns));
	}
}

.make-sm-column-offset(@columns) {
	@media (min-width: @screen-sm-min) {
		margin-left: percentage((@columns / @grid-columns));
	}
}

.make-sm-column-push(@columns) {
	@media (min-width: @screen-sm-min) {
		left: percentage((@columns / @grid-columns));
	}
}

.make-sm-column-pull(@columns) {
	@media (min-width: @screen-sm-min) {
		right: percentage((@columns / @grid-columns));
	}
}

// Generate the medium columns
.make-md-column(@columns; @gutter: @grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left: (@gutter / 2);
	padding-right: (@gutter / 2);

	@media (min-width: @screen-md-min) {
		float: left;
		width: percentage((@columns / @grid-columns));
	}
}

.make-md-column-offset(@columns) {
	@media (min-width: @screen-md-min) {
		margin-left: percentage((@columns / @grid-columns));
	}
}

.make-md-column-push(@columns) {
	@media (min-width: @screen-md-min) {
		left: percentage((@columns / @grid-columns));
	}
}

.make-md-column-pull(@columns) {
	@media (min-width: @screen-md-min) {
		right: percentage((@columns / @grid-columns));
	}
}

// Generate the large columns
.make-lg-column(@columns; @gutter: @grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left: (@gutter / 2);
	padding-right: (@gutter / 2);

	@media (min-width: @screen-lg-min) {
		float: left;
		width: percentage((@columns / @grid-columns));
	}
}

.make-lg-column-offset(@columns) {
	@media (min-width: @screen-lg-min) {
		margin-left: percentage((@columns / @grid-columns));
	}
}

.make-lg-column-push(@columns) {
	@media (min-width: @screen-lg-min) {
		left: percentage((@columns / @grid-columns));
	}
}

.make-lg-column-pull(@columns) {
	@media (min-width: @screen-lg-min) {
		right: percentage((@columns / @grid-columns));
	}
}

