@import "variables";

@font-face {
  font-family: '@{icomoon-font-family}';
  src:
    url('@{icomoon-font-path}/@{icomoon-font-family}.ttf?36rf5n') format('truetype'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.woff?36rf5n') format('woff'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.svg?36rf5n#@{icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '@{icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-stethoscope {
  &:before {
    content: @icon-stethoscope; 
  }
}
.icon-cogs {
  &:before {
    content: @icon-cogs; 
  }
}
.icon-settings {
  &:before {
    content: @icon-settings; 
  }
}
.icon-gears {
  &:before {
    content: @icon-gears; 
  }
}
.icon-options {
  &:before {
    content: @icon-options; 
  }
}
.icon-generate {
  &:before {
    content: @icon-generate; 
  }
}
.icon-pig {
  &:before {
    content: @icon-pig; 
  }
}
.icon-money {
  &:before {
    content: @icon-money; 
  }
}
.icon-bank {
  &:before {
    content: @icon-bank; 
  }
}
.icon-files {
  &:before {
    content: @icon-files; 
  }
}
.icon-stack {
  &:before {
    content: @icon-stack; 
  }
}
.icon-papers {
  &:before {
    content: @icon-papers; 
  }
}
.icon-tablet {
  &:before {
    content: @icon-tablet; 
  }
}
.icon-phone {
  &:before {
    content: @icon-phone; 
  }
}
.icon-phone1 {
  &:before {
    content: @icon-phone1; 
  }
}
.icon-cellular-phone {
  &:before {
    content: @icon-cellular-phone; 
  }
}
.icon-mobile {
  &:before {
    content: @icon-mobile; 
  }
}
.icon-clipboard {
  &:before {
    content: @icon-clipboard; 
  }
}
.icon-list {
  &:before {
    content: @icon-list; 
  }
}
.icon-board {
  &:before {
    content: @icon-board; 
  }
}
.icon-profile-clipboard {
  &:before {
    content: @icon-profile-clipboard; 
  }
}
.icon-profile {
  &:before {
    content: @icon-profile; 
  }
}
.icon-changing-money {
  &:before {
    content: @icon-changing-money; 
  }
}
.icon-user {
  &:before {
    content: @icon-user; 
  }
}
.icon-handshake {
  &:before {
    content: @icon-handshake; 
  }
}
.icon-shuffle {
  &:before {
    content: @icon-shuffle; 
  }
}
.icon-chat {
  &:before {
    content: @icon-chat; 
  }
}
.icon-patient-visit {
  &:before {
    content: @icon-patient-visit; 
  }
}
.icon-insurance-check {
  &:before {
    content: @icon-insurance-check; 
  }
}
.icon-phone2 {
  &:before {
    content: @icon-phone2; 
  }
}
.icon-arrow-right {
  &:before {
    content: @icon-arrow-right; 
  }
}
.icon-schedules {
  &:before {
    content: @icon-schedules; 
  }
}
.icon-facebook {
  &:before {
    content: @icon-facebook; 
  }
}
.icon-instagram {
  &:before {
    content: @icon-instagram; 
  }
}
.icon-twitter {
  &:before {
    content: @icon-twitter; 
  }
}
.icon-youtube {
  &:before {
    content: @icon-youtube; 
  }
}
.icon-authorization {
  &:before {
    content: @icon-authorization; 
  }
}
.icon-card {
  &:before {
    content: @icon-card; 
  }
}
.icon-check-in {
  &:before {
    content: @icon-check-in; 
  }
}
.icon-claims {
  &:before {
    content: @icon-claims; 
  }
}
.icon-delete-circle {
  &:before {
    content: @icon-delete-circle; 
  }
}
.icon-digital-intake {
  &:before {
    content: @icon-digital-intake; 
  }
}
.icon-eligibility {
  &:before {
    content: @icon-eligibility; 
  }
}
.icon-follow-up {
  &:before {
    content: @icon-follow-up; 
  }
}
.icon-image {
  &:before {
    content: @icon-image; 
  }
}
.icon-insurance {
  &:before {
    content: @icon-insurance; 
  }
}
.icon-lock {
  &:before {
    content: @icon-lock; 
  }
}
.icon-money-increase {
  &:before {
    content: @icon-money-increase; 
  }
}
.icon-payment {
  &:before {
    content: @icon-payment; 
  }
}
.icon-stopwatch {
  &:before {
    content: @icon-stopwatch; 
  }
}
.icon-integration-instructions {
  &:before {
    content: @icon-integration-instructions; 
  }
}
.icon-receipt-long {
  &:before {
    content: @icon-receipt-long; 
  }
}
.icon-verified-user {
  &:before {
    content: @icon-verified-user; 
  }
}
.icon-chat-bubble {
  &:before {
    content: @icon-chat-bubble; 
  }
}
.icon-headset-mic {
  &:before {
    content: @icon-headset-mic; 
  }
}
.icon-smartphone {
  &:before {
    content: @icon-smartphone; 
  }
}
.icon-bolt {
  &:before {
    content: @icon-bolt; 
  }
}
.icon-clock {
  &:before {
    content: @icon-clock; 
  }
}
.icon-access-time {
  &:before {
    content: @icon-access-time; 
  }
}
.icon-qrcode {
  &:before {
    content: @icon-qrcode; 
  }
}
.icon-credit-card {
  &:before {
    content: @icon-credit-card; 
  }
}
.icon-display {
  &:before {
    content: @icon-display; 
  }
}
.icon-mobile1 {
  &:before {
    content: @icon-mobile1; 
  }
}
.icon-mobile2 {
  &:before {
    content: @icon-mobile2; 
  }
}
.icon-tablet1 {
  &:before {
    content: @icon-tablet1; 
  }
}
.icon-upload {
  &:before {
    content: @icon-upload; 
  }
}
.icon-bubbles {
  &:before {
    content: @icon-bubbles; 
  }
}
.icon-bubbles2 {
  &:before {
    content: @icon-bubbles2; 
  }
}
.icon-bubble2 {
  &:before {
    content: @icon-bubble2; 
  }
}
.icon-bubbles3 {
  &:before {
    content: @icon-bubbles3; 
  }
}
.icon-cogs1 {
  &:before {
    content: @icon-cogs1; 
  }
}
.icon-power {
  &:before {
    content: @icon-power; 
  }
}
.icon-clipboard1 {
  &:before {
    content: @icon-clipboard1; 
  }
}
.icon-list-numbered {
  &:before {
    content: @icon-list-numbered; 
  }
}
.icon-tree {
  &:before {
    content: @icon-tree; 
  }
}
.icon-upload3 {
  &:before {
    content: @icon-upload3; 
  }
}
.icon-happy {
  &:before {
    content: @icon-happy; 
  }
}
.icon-circle-right {
  &:before {
    content: @icon-circle-right; 
  }
}
.icon-circle-left {
  &:before {
    content: @icon-circle-left; 
  }
}
.icon-tab {
  &:before {
    content: @icon-tab; 
  }
}
.icon-make-group {
  &:before {
    content: @icon-make-group; 
  }
}

