.inner-page-hero-section {
  position: relative;
  background: url("/assets/img/layout/inner-page-hero-background.png") no-repeat center top;
  background-size: cover;
  padding: 19.8rem 0 0;

  @media @max-mobile-screen-size {
    padding: 12rem 0 0;
  }

  .container {
    position: relative;
  }

  .inner-page-hero-title {
    letter-spacing: -.6px;
    margin-bottom: .6rem;
    font-weight: @font-weight-medium;
    color: @brand-primary-color;

    @media @max-tablet-screen-size {
      font-size: 3rem;
      line-height: 1;
      letter-spacing: 0;
    }

    @media @tablet-screen-size {
      max-width: 50%;
    }
  }

  .inner-page-hero-content {
    margin-top: 6.2rem;
    .clearfix();

    @media @max-mobile-screen-size {
      margin-top: 4rem;
    }

    .inner-page-hero-content-icon {
      float: left;
      margin-right: 3rem;
      padding: 0 1.3rem;
      text-align: center;
      font-size: 1.7rem;
      line-height: 1.1;
      font-weight: @font-weight-bold;
      letter-spacing: .6px;
      color: #7a7a7a;

      @media @desktop-screen-size {
        padding: 0 .3rem;
      }

      @media @max-tablet-screen-size {
        width: 13rem;
        margin: 0 0 4rem 0;
        text-align: left;
      }

      @media @max-mobile-screen-size {
        width: 50%;
        margin: 0 0 4rem;
        padding: 0;
        text-align: center;
      }

      .inner-page-hero-content-icon-inner {
        display: inline-block;
        text-align: center;
      }

      .icon {
        display: block;
        font-size: 5rem;
        line-height: 1;
        margin-bottom: 1.5rem;
      }

      span {
        display: block;
      }
    }
  }

  .inner-page-hero-mockup {
    position: absolute;
    top: .9rem;
    right: 7.2rem;
    width: 400px;

    @media @max-tablet-screen-size {
      max-width: 46%;
      right: 0;
      top: -4rem;
    }

    @media @max-mobile-screen-size {
      position: relative;
      right: auto;
      max-width: 100%;
      width: 90%;
      margin: -12.5rem 0 0 5%;
    }

    img {
      max-width: 100%;
    }
  }

  .inner-page-hero-green-bar {
    margin-top: 6.6rem;
    padding: 6.8rem 0;
    background: @brand-primary-color;
    color: white;

    @media @max-mobile-screen-size {
      margin-top: 10rem;
      padding: 6rem 0 3rem;
    }

    .inner-page-hero-green-bar-content {
      max-width: 48rem;

      @media @desktop-screen-size {
        max-width: 40rem;
      }
    }

    p {
      font-size: 1.5rem;
      line-height: 1.5;
      margin-bottom: 2.2rem;
      letter-spacing: .6px;
    }

    .btn {
      margin-top: 1.5rem;
      width: 17rem;
      text-align: center;

      @media @max-mobile-screen-size {
        width: 100%;
      }
    }
  }
}