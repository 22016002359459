.contact-page__main-content {
	padding: 21rem 0 0;

	@media @max-tablet-screen-size {
		padding: 16rem 0 0;
	}

	@media @max-mobile-screen-size {
		padding: 10rem 0 0;
	}
}

.contact-page__form-content {
	padding: 8rem 0;

	@media @max-tablet-screen-size {
		padding: 4rem 0 5rem;
	}

	@media @max-mobile-screen-size {
		padding: 3rem 0;
	}

	& + .request-form-bottom {
		margin-top: 0;
	}
}

.contant-page__small-title {
	display: block;
	margin-bottom: 2rem;
	text-transform: uppercase;
}

.contact-info__item {
	span {
		display: inline-block;
		width: 50px;
		font-weight: 500;
	}
}

.contact-page-map-section {
	height: 43rem;
}
