//  Base Styles  ––––––––––––––––––––––––––––––––––––––––––––––––––

html {
	font-size: 10px;
}

body {

	font-size: @font-size-basic;
	line-height: 2;
	font-weight: 400;
	font-family: @font-primary;
	color: @brand-main-color;
}

//  Typography ––––––––––––––––––––––––––––––––––––––––––––––––––
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	//font-family: @font-titles;
	font-weight: @font-weight-medium;
	margin: 0 0 1.9rem 0;
}

h1, .h1 {
	font-size: @font-size-huge;
	line-height: 3.8rem;
	letter-spacing: 1.65px;
	font-weight: @font-weight-bold;
}

h2, .h2 {
	font-size: @font-size-large;
	line-height: 3.3rem;
	letter-spacing: 1.1px;
	font-weight: @font-weight-bold;
}

h3, .h3 {
	font-size: @font-size-medium;
	line-height: 2rem;
	letter-spacing: .62px;
	font-weight: @font-weight-light;
}

h4, .h4 {
	font-size: @font-size-basic;
}

h5, .h5 {
	font-size: @font-size-small;
}

h6, .h6 {
	font-size: @font-size-tiny;
}

//  Paragraphs ––––––––––––––––––––––––––––––––––––––––––––––––––
p {
	font-size: 1.5rem;
	line-height: 1.25;
	font-weight: @font-weight-light;
	letter-spacing: .6px;
	margin: 0 0 1rem 0;
	&:empty {
		margin: 0;
		line-height: 0;
	}
}

.mod-typo-tiny {
	font-size: @font-size-tiny;
}

.mod-typo-small {
	font-size: @font-size-small;
}

.mod-typo-medium {
	font-size: @font-size-medium;
}

.mod-typo-large {
	font-size: @font-size-large;
}

.mod-typo-huge {
	font-size: @font-size-huge;
}

//  Links ––––––––––––––––––––––––––––––––––––––––––––––––––
a {
	color: @brand-main-color;

	&:hover {
		color: @brand-main-color-hover;
		border-color: @brand-main-color-hover;
	}
}

//  Text Selections ––––––––––––––––––––––––––––––––––––––––––––––––––
::-moz-selection, ::selection {
	color: @text-selection-color;
	background: @text-selection-background;
	text-shadow: none;
}