.monthly-visits-section {
  padding: 10.8rem 0 10.2rem;
  border-bottom: 1px solid #e6e6e6;

  @media @max-mobile-screen-size {
    padding: 5rem 0 4rem;
  }

  > .container {
    position: relative;
  }

  .monthly-visits-image-holder {
    float: left;
    padding-right: 4.5rem;
    .box-sizing();

    @media @desktop-screen-size {
      width: 40%;
    }

    @media @max-tablet-screen-size {
      width: 100%;
      float: none;
      padding: 0;
      text-align: center;
      margin-bottom: 8rem;
    }

    @media @max-mobile-screen-size {
      margin-bottom: 2rem;
    }

    img {
      max-width: 100%;
    }
  }

  .monthly-visits-text-holder {
    width: 32rem;
    float: left;
    padding-right: 4rem;
    margin-top: -4px;
    .box-sizing();

    @media @desktop-screen-size {
      width: 40%;
    }

    @media @max-tablet-screen-size {
      width: 100%;
      float: none;
      margin-bottom: 8rem;
      padding: 0;
    }

    @media @max-mobile-screen-size {
      margin-bottom: 1rem;
    }

    p {
      line-height: 1.97rem;
    }
  }

  .monthly-visits-numbers-holder {
    float: right;

    @media @desktop-screen-size {
      position: absolute;
      top: 0;
      right: 1.5rem;
    }

    @media @tablet-screen-size {
      position: relative;
      top: auto;
      right: auto;
      width: 100%;
      float: none;
      text-align: center;
      margin-bottom: 8rem;
    }

    @media @max-mobile-screen-size {
      float: left;
      margin-top: 2rem;
    }

    .monthly-visits-number {
      display: inline-block;
      font-size: 7.1rem;
      line-height: 7.2rem;
      letter-spacing: -.6px;
      padding-bottom: 1.1rem;
      border-top: 3px solid @brand-primary-color;
      border-bottom: 3px solid @brand-primary-color;
      text-transform: uppercase;
      font-weight: @font-weight-light;
      margin-bottom: 1.6rem;

      @media @desktop-screen-size {
        font-size: 5.1rem;
        line-height: 5.2rem;
        padding-bottom: .8rem;
        margin-bottom: 1rem;
      }
    }

    .monthly-visits-numbers-title {
      font-size: 2rem;
      line-height: 1;
      text-transform: uppercase;
      margin-bottom: .2rem;
      padding-left: .4rem;

      @media @desktop-screen-size {
        font-size: 1.8rem;
      }
    }

    .monthly-visits-numbers-note {
      font-size: 2rem;
      line-height: 1;
      color: @brand-primary-color;
      letter-spacing: .6px;
      padding-left: .4rem;

      @media @desktop-screen-size {
        font-size: 1.8rem;
      }
    }
  }

  // Inner page
  &.inner-page {
    padding: 9.4rem 0 7.5rem;

    @media @max-tablet-screen-size {
      padding: 8rem 0 0;
    }

    .monthly-visits-image-holder {
      width: 50%;
      padding: 0 0 0 1.4rem;

      @media @max-tablet-screen-size {
        width: 100%;
        float: none;
        text-align: center;
        margin-bottom: 8rem;
        padding: 0;
      }
    }

    .monthly-visits-numbers-holder {
      float: left;
      width: 50%;
      padding: 1.5rem 0 0 10.7rem;
      .box-sizing();

      @media @max-tablet-screen-size {
        width: 100%;
        float: none;
        text-align: center;
        margin-bottom: 8rem;
        padding: 0;
      }

      @media @max-mobile-screen-size {
        text-align: left;
        margin-bottom: 5rem;
      }

      .monthly-visits-number {
        font-size: 4.6rem;
        line-height: 1;
        padding: .5rem 0 1.2rem;
        text-transform: none;
        margin-bottom: 2.3rem;
      }

      .monthly-visits-additional-note {
        margin-top: 4.8rem;
        color: #7a7a7a;
      }
    }
  }
}