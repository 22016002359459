.app-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 6.1rem 0 2.1rem;
  z-index: 100;
  .clearfix();

  @media @max-mobile-screen-size {
    padding: 2.5rem 0 0;
  }

  .app-header-logo {
    position: relative;
    display: block;
    width: 218px;
    height: 19px;
    margin-top: 23px;
    background: url("/assets/img/svg/logo.svg") no-repeat left top;
    background-size: 100% auto;
    float: left;
    z-index: 120;

    @media @max-mobile-screen-size {
      width: 18rem;
    }
  }

  .app-header-menu {
    display: none;
    position: absolute;
    top: 3.5rem;
    right: 2rem;
    width: 30px;
    height: 20px;
    .transform(rotate(0deg));
    .transition(.5s ease-in-out);
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    z-index: 130;

    @media @tablet-screen-size {
      top: 7.3rem;
      right: 4.5rem;
    }

    @media @max-tablet-screen-size {
      display: block;
    }

    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: @brand-secondary-color;
      border-radius: 2px;
      opacity: 1;
      left: 0;
      .transform(rotate(0deg));
      .transition(.25s ease-in-out);

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 8px;
      }

      &:nth-child(4) {
        top: 16px;
      }
    }

    &.active {
      span {
        background: @brand-primary-color;

        &:nth-child(1) {
          top: 8px;
          width: 0;
          left: 50%;
        }

        &:nth-child(2) {
          .transform(rotate(45deg));
        }

        &:nth-child(3) {
          .transform(rotate(-45deg));
        }

        &:nth-child(4) {
          top: 9px;
          width: 0;
          left: 50%;
        }
      }
    }
  }

  .app-header-nav {
    display: block;
    float: right;
    padding-top: 1.2rem;

    @media @max-tablet-screen-size {
      position: fixed;
      top: 0;
      left: 100%;
      right: 0;
      bottom: 0;
      z-index: 100;
      min-width: 20rem;
      background: white;
      padding-top: 10rem;
      .transition(.2s ease-in-out);
    }

    @media @tablet-screen-size {
      padding: 16rem 3rem 0;
      .transition(.4s ease-in-out);
    }

    &.is-expanded {
      @media @max-tablet-screen-size {
        left: 0;
      }
    }

    .app-header-nav-link {
      display: inline-block;
      padding: 1rem 0 .9rem;
      margin: 0 0 0 4.3rem;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 1;
      // font-family: @font-secondary;
      text-transform: uppercase;
      letter-spacing: .5px;
      border-bottom: 1px solid transparent;
      cursor: pointer;
      text-decoration: none;

      @media @max-tablet-screen-size {
        display: block;
        margin: 0 2rem;
        padding: 2rem 0;
      }

      &:hover {
        text-decoration: none;
        color: @brand-secondary-color;

        @media @min-tablet-screen-size {
          color: @brand-secondary-color;
          border-bottom-color: @brand-primary-color;
        }
      }

      &.active {
        color: @brand-secondary-color;
        border-bottom-color: @brand-primary-color;
      }

      &.login {
        color: @brand-primary-color;
        margin: 0 0 0 1.6rem;

        @media @max-tablet-screen-size {
          color: @brand-secondary-color;
          margin: 0 2rem;
        }
      }
    }

    .request-demo {
      margin-left: 5.7rem;
      text-decoration: none;

      @media @max-tablet-screen-size {
        display: block;
        color: @brand-secondary-color;
        border: none;
        padding: 2rem 0;
        margin: 0 2rem;
        height: auto;
        line-height: 1;
      }

      &:hover {
        @media @max-tablet-screen-size {
          background: transparent;
          border-bottom: none;
          .border-radius(0);
        }
      }
    }
  }
}

body.menu-is-opened {
  overflow: hidden;

  .app-header {
    position: fixed;
  }
}