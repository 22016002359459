.management-solution-section {
  background: @brand-primary-color;
  border-top: 1px solid white;

  @media @desktop-screen-size {
    overflow: hidden;
  }

  br {
    @media @max-mobile-screen-size {
      display: none;
    }
  }

  > .container {
    // padding-top: 7.4rem;
    // padding-bottom: 7.4rem;
    position: relative;

    @media @max-mobile-screen-size {
      padding: 4rem 2rem 0;
    }
  }

  .management-solution-title {
    color: white;
    font-size: 3.5rem;
    line-height: 3.7rem;
    letter-spacing: 1.7px;
    margin-top: 7.4rem;
    margin-bottom: 2rem;

    @media @max-mobile-screen-size {
      font-size: 2.8rem;
    }
  }

  .management-solution-text {
    max-width: 47rem;
    color: white;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: .7px;
    // font-weight: @font-weight-light;
    margin-bottom: 3rem;
  }

  .management-solution-image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12rem;

    @media @desktop-screen-size {
      width: 60rem;
      height: 34rem;
    }

    @media @max-tablet-screen-size {
      position: relative;
      bottom: -7.4rem;
      right: auto;
      width: 100%;
    }

    @media @max-mobile-screen-size {
      position: relative;
      bottom: auto;
      width: 100%;
      height: auto;
      margin-top: 4rem;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      float: right;
    }
  }

  .btn {
    margin-bottom: 7.4rem;

    @media @max-mobile-screen-size {
      display: block;
      text-align: center;
    }
  }
}