.thank-you-overlay {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	z-index: 999;
	background: @brand-primary-color;

	.thank-you-holder {
		position: relative;
		background: @white;
		width: 40rem;
		margin: 12.5rem auto;
		padding: 3rem 3.3rem;
		.border-radius(.5rem);
		.box-sizing();
	}

	.thank-you-logo {
		display: block;
		width: 9rem;
		height: 2.3rem;
		background: url("/assets/img/svg/logo.svg") no-repeat left top;
		background-size: 100% auto;
	}

	.thank-you-close-icon {
		position: absolute;
		top: 2.9rem;
		right: 3.1rem;
		display: block;
		width: 2rem;
		height: 2rem;
		cursor: pointer;

		&:before,
		&:after {
			position: absolute;
			top: .8rem;
			left: 0;
			content: '';
			display: block;
			width: 2rem;
			height: .3rem;
			.border-radius(.3rem);
			.transform(rotate(45deg));
			background: @brand-primary-color;
		}

		&:after {
			.transform(rotate(-45deg));
		}
	}

	.thank-you-content {
		padding: 3rem 0 0 2.2rem;

		p {
			font-size: 1rem;
			line-height: 1.2;
			margin-bottom: 2rem;
		}
	}

	.thank-you-title {
		font-size: 2rem;
		line-height: 1.2;
		font-weight: @font-weight-light;
		margin-bottom: 1.2rem;
		color: @brand-primary-color;

		b {
			display: block;
			font-weight: @font-weight-bold;
		}
	}
}